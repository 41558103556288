import React from 'react';

import Layout from 'components/core/layout';
import Seo from 'components/core/seo';

const Bookshelf: React.FC = () => {
  return (
    <Layout>
      <Seo title="Bookshelf" />
    </Layout>
  );
};

export default Bookshelf;
